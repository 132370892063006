import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HorWebsite from './HorWebsite.js'
import VerWebsite from './VerWebsite.js'
import './App.css'

export default class App extends React.Component {
  render() {
    return (
      <Router basename="/">
        <Routes>
          <Route
            exact
            path="/"
            element={
              document.documentElement.clientWidth >
              document.documentElement.clientHeight ? (
                <HorWebsite />
              ) : (
                <VerWebsite />
              )
            }
          />
        </Routes>
      </Router>
    )
  }
}
