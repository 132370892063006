import React from 'react'
import './App.css'
import './Ver.css'
import ProductIcon from './product-icon.png'
import BackgroundVer from './background-ver.png'
import MySnapshotImg from './my-snapshot.png'
import MySnapshot2Img from './my-snapshot-2.png'

export default class VerWebsite extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  componentWillUnmount() {}

  componentDidMount() {}

  render() {
    return (
      <div className="ver-app">
        <img src={BackgroundVer} className="ver-app-back" />

        <div className="ver-app-body">
          <div className="ver-app-snapshot-container">
            <div className="ver-app-snapshot-wrapper">
              <img src={MySnapshotImg} className="ver-app-snapshot" />
            </div>

            <div className="ver-app-snapshot-wrapper-2">
              <img src={MySnapshot2Img} className="ver-app-snapshot-2" />
            </div>
          </div>

          <div className="ver-app-name">APP介绍</div>

          <div className="ver-app-desc">
            《回答》APP是为学生、科研和技术工作者等用户群体设计开发的一款以专业学科知识问答为主题的移动APP平台。用户可以在平台上以图文形式进行提问，并可通过平台通用资产对问题进行一定金额的悬赏。其他用户可以接单回答问题，与提问者进行一对一实时聊天交流以快速解决问题，并且获得相应报酬。平台支持图文和语音等多种信息形式来方便用户之间的沟通交流。APP旨在打造一个覆盖理工科、文科、医科、艺术体育等全领域学科知识的综合性问答平台，帮助所有在学习和工作中遇到疑难困惑的用户，并且也为专业人士提供一个有效的知识变现渠道。
          </div>

          {/* <div className="ver-code-name">安卓下载</div>

          <img src={QrCodeBoth} className="ver-code" /> */}

          <div className="ver-contact-info">
            {
              '地址：上海市奉贤区奉浦工业区奉浦大道111号5楼2467室\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0联系电话：13162737530\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0邮箱：shanghaiazir@126.com'
            }
          </div>

          <a
            className="ver-copywrite"
            href="https://beian.miit.gov.cn"
            target="_blank"
          >
            版权所有©2023-2024 上海阿兹尔网络科技有限公司沪ICP备18018511号-9
          </a>
        </div>

        <div className="ver-head">
          <img src={ProductIcon} className="ver-head-icon" />

          <div className="ver-head-content">回答</div>

          <div className="ver-head-link">
            <a
              className="ver-head-contract"
              href="https://www.smoothqna.com/qaapp/contract-terms"
              target="_blank"
            >
              用户协议
            </a>

            <a
              className="ver-head-privacy"
              href="https://www.smoothqna.com/qaapp/privacy-policy"
              target="_blank"
            >
              隐私政策
            </a>
          </div>
        </div>
      </div>
    )
  }
}
