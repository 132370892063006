import React from 'react'
import './App.css'
import './Hor.css'
import MoreDotPic from './more-dot-pic.png'
import ProductIcon from './product-icon.png'
import BackgroundHor from './background-hor.jpg'
import MySnapshotImg from './my-snapshot.png'
import MySnapshot2Img from './my-snapshot-2.png'

// 背景图 高 / 宽 = 640.0 / 960
const BackgroundImgAspect = 640 / 960

// 手机截图 高 / 宽 = 2000 / 1157； dimmy.club； CONTAIN模式； Samsung Galaxy S8
// 手机高 / 截图高 = 1491 / 1905
const SnapshotImgAspect = (2000.0 / 1157) * (1491.0 / 1905)

// 手机截图 宽/屏幕宽； .hor-app-content-snapshot； width
const SnapshotWidth = 0.5 * 0.55

export default class HorWebsite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      headerHeight: 0,
      contentHeight: 0,
    }
  }

  componentWillMount() {}

  componentWillUnmount() {}

  componentDidMount() {
    this.setState({
      headerHeight: document.documentElement.clientWidth * BackgroundImgAspect,
      contentHeight:
        document.documentElement.clientWidth *
        SnapshotWidth *
        SnapshotImgAspect,
    })
  }

  render() {
    return (
      <div className="hor-app">
        <img src={BackgroundHor} className="hor-app-back" />

        <div className="hor-app-content">
          <div className="hor-app-content-left">
            <img src={MySnapshotImg} className="hor-app-content-snapshot" />

            <img src={MySnapshot2Img} className="hor-app-content-snapshot-2" />
          </div>

          <div
            className="hor-app-content-right"
            style={{ height: this.getHeaderHeight() }}
          >
            <div
              className="hor-content-body"
              style={{ height: this.getContentHeight() }}
            >
              <div className="hor-product">
                {/* <img src={ProductIcon} className="hor-product-icon" /> */}

                <div className="hor-product-name">APP介绍</div>
              </div>

              <div className="hor-product-desc">
                《回答》APP是为学生、科研和技术工作者等用户群体设计开发的一款以专业学科知识问答为主题的移动APP平台。用户可以在平台上以图文形式进行提问，并可通过平台通用资产对问题进行一定金额的悬赏。其他用户可以接单回答问题，与提问者进行一对一实时聊天交流以快速解决问题，并且获得相应报酬。平台支持图文和语音等多种信息形式来方便用户之间的沟通交流。APP旨在打造一个覆盖理工科、文科、医科、艺术体育等全领域学科知识的综合性问答平台，帮助所有在学习和工作中遇到疑难困惑的用户，并且也为专业人士提供一个有效的知识变现渠道。
              </div>

              <div className="hor-feature">
                <div className="hor-feature-half">
                  <div className="hor-feature-up">
                    <img src={MoreDotPic} className="hor-feature-img" />

                    <div className="hor-feature-txt">专业细分</div>
                  </div>

                  <div className="hor-feature-down">
                    <img src={MoreDotPic} className="hor-feature-img" />

                    <div className="hor-feature-txt">大神云集</div>
                  </div>
                </div>

                <div className="hor-feature-half">
                  <div className="hor-feature-up">
                    <img src={MoreDotPic} className="hor-feature-img" />

                    <div className="hor-feature-txt">包罗万象</div>
                  </div>

                  <div className="hor-feature-down">
                    <img src={MoreDotPic} className="hor-feature-img" />

                    <div className="hor-feature-txt">高质量交流</div>
                  </div>
                </div>
              </div>

              {/* <div className="hor-download">
                <div className="hor-code-name">安卓下载</div>

                <img src={QrCodeBoth} className="hor-code" />
              </div> */}
            </div>
          </div>
        </div>

        <div
          className="hor-contact-info"
          style={{
            top: this.getContactTop(),
          }}
        >
          {
            '地址：上海市奉贤区奉浦工业区奉浦大道111号5楼2467室\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0联系电话：13162737530\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0邮箱：shanghaiazir@126.com'
          }
        </div>

        <a
          className="hor-copywrite"
          style={{ top: this.getCopywriteTop() }}
          href="https://beian.miit.gov.cn"
          target="_blank"
        >
          版权所有©2023-2024 上海阿兹尔网络科技有限公司沪ICP备18018511号-9
        </a>

        <div className="hor-head">
          <img src={ProductIcon} className="hor-head-icon" />

          <div className="hor-head-content">回答</div>

          <div className="hor-head-link">
            <a
              className="hor-head-contract"
              href="https://www.smoothqna.com/qaapp/contract-terms"
              target="_blank"
            >
              用户协议
            </a>

            <a
              className="hor-head-privacy"
              href="https://www.smoothqna.com/qaapp/privacy-policy"
              target="_blank"
            >
              隐私政策
            </a>
          </div>
        </div>
      </div>
    )
  }

  getHeaderHeight = () => {
    console.log('header height = ' + this.state.headerHeight)
    return this.state.headerHeight
  }

  getContentHeight = () => {
    console.log('content height = ' + this.state.contentHeight)
    return this.state.contentHeight
  }

  getContactTop = () => {
    let vmin = Math.min(
      document.documentElement.clientWidth,
      document.documentElement.clientHeight
    )
    let copywriteHeight = vmin * 0.025
    let copywriteBottom = vmin * 0.115
    console.log(
      'copywrite params = ' +
        this.state.headerHeight +
        ' ' +
        copywriteHeight +
        ' ' +
        copywriteBottom +
        ' ' +
        vmin +
        ' ' +
        document.documentElement.clientWidth +
        ' ' +
        document.documentElement.clientHeight
    )
    return this.state.headerHeight - copywriteHeight - copywriteBottom
  }

  getCopywriteTop = () => {
    let vmin = Math.min(
      document.documentElement.clientWidth,
      document.documentElement.clientHeight
    )
    let copywriteHeight = vmin * 0.015
    let copywriteBottom = vmin * 0.035
    console.log(
      'copywrite params = ' +
        this.state.headerHeight +
        ' ' +
        copywriteHeight +
        ' ' +
        copywriteBottom +
        ' ' +
        vmin +
        ' ' +
        document.documentElement.clientWidth +
        ' ' +
        document.documentElement.clientHeight
    )
    return this.state.headerHeight - copywriteHeight - copywriteBottom
  }
}
